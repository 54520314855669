.info-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #ABC4FF;
  overflow: hidden;
}

.MainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 720px;
  width: 720px;
  border: 1px;
  border-radius: 9999px;
  background-color: #ffffff;
  max-width: 100vw;
  max-height: 100vh;
}

.ripple {
  position: absolute;
  top: 133%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-radius: 9999px;
  opacity: 0;
  animation: rippleEffect 6s infinite;
}

.ripple1 {
  width: 150px;
  height: 150px;
  animation-delay: 0.5s;
}

.ripple2 {
  width: 200px;
  height: 200px;
  animation-delay: 0s;
}


@keyframes rippleEffect {
  0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
  }
  100% {
      transform: translate(-50%, -50%) scale(10);
      opacity: 0;
  }
}

.login-page-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

#loginloginform {
  top: -17.5%;
}

.login-page-title {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: clamp(1.2rem, 1vw, 1.25rem);
  font-weight: bold;
  padding: 5px;
  word-wrap: break-word; /* Break the words if they don't fit */
}

h4 {
  width: 600px; /* Set a fixed width to squish content */
  word-wrap: break-word; /* Break the words if they don't fit */
  padding-bottom: 3%;
  font-size: clamp(1rem, 1vw, 1.1rem);
}

h6 {
  width: 600px; /* Set a fixed width to squish content */
  word-wrap: break-word; /* Break the words if they don't fit */
  font-size: clamp(1rem, 1vw, 1.1rem);
}

.descclass {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .MainInfo{
    height: 400px;
    width: 400px;
  }

  #loginloginform {
    top: -10%;
  }

  .login-page-title{
    font-size: clamp(0.6rem, 1vw, 1.25rem);
  }

  h4 {
    font-size: clamp(0.55rem, 1vw, 1.15rem);
    width: 80%;
  }

  h6 {
    width: 50%;
    font-size: clamp(0.55rem, 1vw, 1.15rem);
  }

  .ripple {
    top: 143%;
    left: 50%;
  }
}

@media (min-width: 600px) and (max-width: 880px) {
  .MainInfo{
    height: 650px;
    width: 650px;
  }

  #loginloginform {
    top: -10%;
  }

  .login-page-title{
    font-size: clamp(1.15rem, 1vw, 2.5rem);
  }

  h4 {
    font-size: clamp(0.9rem, 1vw, 1.75rem);
    width: 80%;
  }

  h6 {
    width: 50%;
    font-size: clamp(0.9rem, 1vw, 1.75rem);
  }

  .ripple {
    top: 149%;
    left: 50%;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .MainInfo{
    height: 720px;
    width: 720px;
  }

  #loginloginform {
    top: -10%;
  }

  .login-page-title{
    font-size: clamp(1.2rem, 1vw, 1.25rem);
  }

  h4 {
    font-size: clamp(1rem, 1vw, 1.1rem);
    width: 80%;
  }

  h6 {
    width: 50%;
    font-size: clamp(1rem, 1vw, 1.1rem);
  }

  .ripple {
    top: 158.5%;
    left: 50%;
  }
}

@media (min-width: 1440px) {
  .MainInfo{
    height: 720px;
    width: 720px;
  }

  #loginloginform {
    top: -10%;
  }

  .login-page-title{
    font-size: clamp(1.2rem, 1vw, 1.25rem);
  }

  h4 {
    font-size: clamp(1rem, 1vw, 1.1rem);
    width: 80%;
  }

  h6 {
    width: 50%;
    font-size: clamp(1rem, 1vw, 1.1rem);
  }

  .ripple {
    top: 219%;
    left: 50%;
  }
}